import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, connectAuthEmulator, getAuth, setPersistence } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import { clientConfig } from '../firebaseConfig';

// Initialize Firebase services.
const app = initializeApp(clientConfig);
const analytics = {}; //getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);
// set persistence to local
setPersistence(auth, browserSessionPersistence);
// setPersistence(auth, inMemoryPersistence);

const storage = getStorage(app);
const functions = getFunctions(app);

/**
 * To use firebase emulator
 * start firebase emulator: firebase emulators:start
 * start react app: yarn start
 */
if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_FIREBASE_EMULATOR?.toLowerCase() === 'active') {
  console.log(`*** FIREBASE_EMULATOR:  ${process.env.NEXT_PUBLIC_FIREBASE_EMULATOR} ***`);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}

export { auth, firestore, analytics, functions, storage };
