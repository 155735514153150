'use client';

export function useLocalStorage() {
  const setLocalStorageValue = (key: string, initialValue: string) => {
    // 'use client' renders this function client side, but it is hydrated on the server
    // avoid log pollution by checking for `typeof localStorage` (note: `!localStorage` is not sufficient)
    if (typeof localStorage == 'undefined') {
      return undefined;
    }
    localStorage.setItem(key, JSON.stringify(initialValue));
  };

  const getLocalStorageValue = (key: string) => {
    if (typeof localStorage == 'undefined') {
      return undefined;
    }

    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  const deleteLocalStorageValue = (key: string) => {
    if (typeof localStorage == 'undefined') {
      return undefined;
    }
    localStorage.setItem(key, '');
  };

  return {
    setLocalStorageValue,
    getLocalStorageValue,
    deleteLocalStorageValue,
  };
}
