'use client';

import { Buffer } from 'buffer';
import base64url from 'base64url';
import { useEffect, useMemo, useState } from 'react';

import { MarketplaceSchoolBodyType } from '../external-apis/types';
import { useLocalStorage } from './useLocalStorage';

export const BRANDING_STATE_KEY = 'bnd'; // BraND state key
export const RETURN_URL_KEY = 'ret'; // RETurn
export const RETURN_JWT_KEY = 'jwt'; // JWT
export const RETURN_STATE_KEY = 'rts'; // ReTurn State
export const RETURN_EXT_KEY = 'ext'; // EXTernal

const CCMS_APP_URL = process.env.NEXT_PUBLIC_WS_CCMS_APP_URL;
const WS_AUTH_URL = process.env.NEXT_PUBLIC_WS_AUTH_URL;

interface AdditionalDataParams {
  [key: string]: boolean | number | string;
}

export type BrandingData = {
  networkAutoMigrateCcms: boolean;
  favicon?: {
    href: string;
    rel: string;
    sizes: string;
    type: string;
  };
  faviconSmall?: {
    href: string;
    rel: string;
    sizes: string;
    type: string;
  };
  logo?: string;
  logoSmall?: string;
};

export type ReturnStatePayload = {
  mode?: string;
  type?: string;
  networkId?: string;
  newSupply?: boolean;
  source?: string;
};
export type ExternalStatePayload = {
  signup?: MarketplaceSchoolBodyType & {
    lang?: string;
    source?: string;
    segmentData?: {
      event: string;
      data: {
        sessionId: string;
      };
    };
    type?: string;
    networkId?: string;
    mode: 'signup';
    first_name: string;
    last_name: string;
  };
  lang?: string;
  ses?: string;
};

export type ParsedHash = {
  [BRANDING_STATE_KEY]?: BrandingData;
  [RETURN_URL_KEY]?: string;
  [RETURN_JWT_KEY]?: string;
  [RETURN_EXT_KEY]?: ExternalStatePayload;
  [RETURN_STATE_KEY]?: ReturnStatePayload;
};

export const LOCAL_STORAGE_HASH_KEY = 'onboarding_hash';

interface QSType {
  hash: string | null;
  jwt: string;
  parsedHash?: ParsedHash;
  getRedirectUrl: (additionalData?: AdditionalDataParams) => Promise<string>;
}

export default function useQueryString(): QSType {
  const [hash, setHash] = useState<string | null>(null);
  const { setLocalStorageValue } = useLocalStorage();

  useEffect(() => {
    if (window?.location?.hash && !hash) {
      setHash(window.location.hash);
      return;
    }
  }, [hash]);

  const parsedHash = useMemo(() => {
    if (!hash) return null;
    const payloadStr = hash?.split('#')?.[1];
    const rawDecodedJSON = base64url.decode(payloadStr);
    return JSON.parse(rawDecodedJSON);
  }, [hash]);

  useEffect(() => {
    if (parsedHash) {
      setLocalStorageValue(LOCAL_STORAGE_HASH_KEY, parsedHash);
    }
  }, [parsedHash]);

  const jwt = parsedHash?.[RETURN_JWT_KEY];

  const getRedirectUrl = async (additionalData?: AdditionalDataParams) => {
    const base = WS_AUTH_URL;

    const redirectHash = Buffer.from(
      JSON.stringify({
        [RETURN_URL_KEY]: `${CCMS_APP_URL || 'https://ccms.wonderschool.com'}/auth-return`,
        [RETURN_STATE_KEY]: {
          mode: 'signin',
        },
        ...additionalData,
      })
    ).toString('base64');

    return base + '#' + redirectHash;
  };

  return { jwt, hash, parsedHash, getRedirectUrl };
}
