'use client';

import { User } from 'firebase/auth';
import { usePathname, useRouter } from 'next/navigation';
import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';

import LoadingSpinner from '../components/LoadingSpinner';
import { CLAIMS } from '../constants/auth';
import auth, { signInWithEmail, signInWithToken, signOut, startAuthListener } from '../firebase/auth';
import { getUserListener } from '../firebase/user';
import useQueryString, { BRANDING_STATE_KEY, BrandingData, ParsedHash } from '../hooks/useQueryString';
import useLogger from '../rollbar/logger';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../routes/routes';
import { AuthError, AuthType, CustomClaimType, UserRecord, UserType } from '../types/firebase';
import { getErrorMessage } from '../utils/error';

type State = {
  user: UserType;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authError: AuthError;
  claimsSet: boolean;
  parsedHashState?: ParsedHash | null;
  brandingData?: BrandingData | null;
};

type Action =
  | { type: 'SIGN_IN' }
  | { type: 'SET_USER'; payload: UserType }
  | { type: 'SET_ERROR'; payload: AuthError }
  | { type: 'SET_CLAIMS_SET' }
  | { type: 'SET_PARSED_HASH'; payload: ParsedHash }
  | { type: 'SIGN_OUT' };

const initialState: State = {
  user: null,
  isAuthenticating: true,
  isAuthenticated: false,
  authError: null,
  claimsSet: false,
  parsedHashState: null,
  brandingData: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: true,
        authError: null,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        isAuthenticating: false,
        isAuthenticated: action.payload ? true : false,
        authError: null,
        claimsSet: action.payload?.claims?.[CLAIMS.ORGANIZATION_ADMIN] ?? false,
      };
    case 'SET_ERROR':
      return {
        ...state,
        authError: action.payload,
        isAuthenticating: false,
        isAuthenticated: false,
      };
    case 'SET_CLAIMS_SET':
      return {
        ...state,
        claimsSet: true,
      };
    case 'SET_PARSED_HASH':
      return {
        ...state,
        parsedHashState: action.payload,
        brandingData: action.payload[BRANDING_STATE_KEY],
      };
    case 'SIGN_OUT':
      return initialState;
    default:
      return state;
  }
};

type AuthContextProps = {
  currentUser: UserType;
  claimsSet: boolean;
  startAuthListener: AuthType['startAuthListener'];
  signInWithEmail: AuthType['signInWithEmail'];
  signInWithToken: AuthType['signInWithToken'];
  signOut: AuthType['signOut'];
  setClaimsSet: () => void;
} & State;

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const [{ user, isAuthenticating, isAuthenticated, authError, claimsSet, parsedHashState, brandingData }, dispatch] =
    useReducer(reducer, initialState);
  const { jwt, parsedHash, hash } = useQueryString();
  const router = useRouter();
  const pathname = usePathname();
  const logger = useLogger();
  const [userDocument, setUserDocument] = useState<UserRecord | null>(null);
  // this indicates when any signin attempts have resolved.
  const [userSignedIn, setUserSignedIn] = useState(false);
  // this determines whether all signin paths have been resolved
  // and user state can be checked for routing.
  const [sessionVerified, setSessionVerified] = useState(false);

  const [loading, setLoading] = useState(true);

  const onErrorCallback = (error: unknown) => {
    logger.log('error starting user record listener', {
      error,
    });
  };

  // main effect controlling auth routing
  useEffect(() => {
    // wait until auth check completes
    if (isAuthenticating || !sessionVerified) {
      return;
    }

    // when a user signs out via SSO, this session remains active
    // therefore, if a user is attempting to reach the home route without a jwt,
    // then we must treat them as unauthenticated and sign them out.
    if (isAuthenticated && !jwt) {
      if (pathname === PUBLIC_ROUTES.LANDING || pathname === PUBLIC_ROUTES.OLD_ONBOARDING) {
        auth?.signOut();
        setLoading(false);
        return;
      }
    }

    // ensure the user is on a public route
    if (!isAuthenticated && !jwt) {
      // check if route is public.
      if (Object.values(PUBLIC_ROUTES).includes(pathname as PUBLIC_ROUTES)) {
        setLoading(false);
        return;
        // else route to home
      } else {
        router.push(PUBLIC_ROUTES.LANDING);
        return;
      }
    }

    // is authenticated. make sure the user is not already set up with an org
    // if user document is found && has org -> send to CCMS
    // the only exception to this is the finalize route as it's the expected exit point
    if (
      pathname !== PRIVATE_ROUTES.ONBOARDING_FINALIZE &&
      Object.keys(userDocument || {}).length > 0 &&
      (userDocument?.defaultOrganization || userDocument?.organizations)
    ) {
      router.push(`${process.env.NEXT_PUBLIC_WS_CCMS_APP_URL}`);
      return;
    }

    // is authenticated. ensure they're on a private route
    const userDocIsReady = userDocument != null && Object.keys(userDocument || {}).length === 0;
    const isOnPublicRoute = !Object.values(PRIVATE_ROUTES).includes(pathname as PRIVATE_ROUTES);

    if (userDocIsReady && isOnPublicRoute) {
      // persistence is set to session, so the hash can be discarded
      router.replace(PRIVATE_ROUTES.PROGRAM_INFO);
    }

    // they are authenticated and on a private route
    if (userDocIsReady && !isOnPublicRoute) {
      // remove the hash from the url
      if (hash) {
        // nextjs removed the shallow reroute option in this version, so use vanilla js instead
        window?.history?.pushState(null, '', pathname as string);
      }
      setLoading(false);
    }
  }, [isAuthenticating, sessionVerified, isAuthenticated, router, userDocument, user]);

  useEffect(() => {
    if (Object.values(PRIVATE_ROUTES).includes(pathname as PRIVATE_ROUTES)) {
      if (userDocument) {
        setLoading(false);
      }
    }
  }, [pathname]);

  // store any parsed hash received...
  useEffect(() => {
    if (parsedHash && !parsedHashState) {
      dispatch({
        type: 'SET_PARSED_HASH',
        payload: parsedHash,
      });
    }
  }, [parsedHash, parsedHashState]);

  // init the auth listener
  useEffect(() => {
    dispatch({ type: 'SIGN_IN' });

    const unsubscribe = startAuthListener(
      async (user: User | null) => {
        if (user) {
          const claims = (await auth?.currentUser?.getIdTokenResult())?.claims as CustomClaimType;

          dispatch({
            type: 'SET_USER',
            payload: { ...user, claims },
          });
        } else {
          dispatch({
            type: 'SET_USER',
            payload: null,
          });
        }
      },
      (error) => {
        dispatch({
          type: 'SET_ERROR',
          payload: getErrorMessage(error, 'Some error occurred in AuthChangeListener'),
        });
      }
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (userSignedIn && !isAuthenticating && !sessionVerified) {
      setSessionVerified(true);
    }
  }, [userSignedIn, isAuthenticating, sessionVerified]);

  const signInWithEmailFn = useCallback(async (email: string, password: string) => {
    try {
      dispatch({ type: 'SIGN_IN' });
      const signedInUser = await signInWithEmail(email, password);
      return signedInUser;
    } catch (error) {
      const { message } = getErrorMessage(error, 'Unable to Sign in, invalid username and/or password.');

      dispatch({
        type: 'SET_ERROR',
        payload: {
          message,
        },
      });
      throw new Error(message);
    }
  }, []);

  const signInWithTokenFn = useCallback(async (token: string) => {
    try {
      dispatch({ type: 'SIGN_IN' });
      const signedInUser = await signInWithToken(token);
      setUserSignedIn(true);
      return signedInUser;
    } catch (error) {
      const { message } = getErrorMessage(error, 'Unable to Sign in, invalid token.');
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message,
        },
      });
      setUserSignedIn(true);
      return null;
    }
  }, []);

  const signOutFunction = useCallback(async () => {
    try {
      dispatch({
        type: 'SIGN_OUT',
      });

      return await signOut();
    } catch (error) {
      const { message } = getErrorMessage(error, 'Problem signing out');
      dispatch({
        type: 'SET_ERROR',
        payload: { message },
      });
      throw new Error(message);
    }
  }, []);

  const setClaimsSet = useCallback(() => {
    try {
      dispatch({ type: 'SET_CLAIMS_SET' });
    } catch (error) {
      logger.error('error setting claim set');
    }
  }, []);

  // try sign in with the token
  useEffect(() => {
    if (jwt && !user) {
      try {
        signInWithTokenFn(jwt);
      } catch (error) {
        // swallow error as this might be a result of a user already being signed in
      }
      return;
    }
    if (!userSignedIn) {
      setUserSignedIn(true);
    }
  }, [jwt, signInWithTokenFn, userSignedIn, user]);

  // check if a user document exists
  useEffect(() => {
    if (user?.uid && !userDocument) {
      getUserListener({
        userId: user.uid,
        observer: {
          onNextFn: (user: UserRecord) => {
            if (!userDocument) {
              setUserDocument(user);
            }
          },
          onErrorFn: onErrorCallback,
        },
      });
    }
  }, [user, userDocument]);

  // TODO: replace this with the universal loading page provided by the design team
  if (loading) {
    return (
      <div className="min-h-screen h-full">
        {/* 
          not sure why, but none of the classes below are applied as expected:
          "mt-20, mx-20, pt-20, px-20"
          doing an inline style works fine. 
        */}
        <div className="w-full h-full" style={{ marginTop: '5rem' }}>
          <LoadingSpinner show={true} />
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticating,
        isAuthenticated,
        authError,
        currentUser: auth.currentUser,
        claimsSet,
        parsedHashState,
        brandingData,
        setClaimsSet,
        startAuthListener,
        signInWithEmail: signInWithEmailFn,
        signInWithToken: signInWithTokenFn,
        signOut: signOutFunction,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
