import {
  CompleteFn,
  ErrorFn,
  getIdTokenResult,
  NextOrObserver,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut as signOutUser,
  User,
} from 'firebase/auth';

import { auth } from './index';

export function startAuthListener(
  callback: NextOrObserver<User>,
  errorCallback?: ErrorFn | undefined,
  completeCallback?: CompleteFn | undefined
) {
  return onAuthStateChanged(auth, callback, errorCallback, completeCallback);
}

export function signInWithEmail(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function signInWithToken(token: string) {
  return signInWithCustomToken(auth, token);
}

export async function getUserAccessToken(user: User) {
  return await getIdTokenResult(user, true);
}

export async function getCurrentUserToken() {
  return await auth?.currentUser?.getIdToken(true);
}

export function signOut() {
  return signOutUser(auth);
}

export const currentUser = auth?.currentUser || {};

export default auth;
