export enum CLAIMS {
  MOXIT_ADMIN = 'moxitAdmin',
  ORGANIZATION_ADMIN = 'organizationAdmin',
  ORGANIZATION_DEVICE = 'organizationDevice',
  LOCATION_ADMIN = 'locationAdmin',
  SUPPORT_STAFF = 'supportStaff',
  TEACHER = 'teacher',
  PARENT = 'parent',
  GUARDIAN = 'guardian',
  EMERGENCY_CONTACT = 'emergencyContact',
}
