export enum PUBLIC_ROUTES {
  OLD_ONBOARDING = '/old/onboarding',
  AUTH = '/auth',
  NOT_FOUND = '/not-authorized',
  LANDING = '/',
  EMAIL = '/email',
  PROVIDER_SELECT = '/provider-select',
}

// TODO: remove or build out signout route
export enum PRIVATE_ROUTES {
  PROGRAM_INFO = '/program-info',
  ONBOARDING_FINALIZE = '/finalize',
  OLD_ONBOARDING_ACCOUNT = '/old/onboarding-account',
  OLD_ONBOARDING_FINALIZE = '/old/onboarding-finalize',
  SIGN_OUT = '/signout',
}
