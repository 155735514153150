import { UserRecord } from '../types/firebase';
import { startCollectionDocListener } from './firestore';

interface Props {
  userId: string;
  observer: {
    onNextFn: (snapshot: UserRecord) => void;
    onErrorFn: (error: unknown) => void;
    onCompleteFn?: () => void;
  };
}

export const getUserListener = async ({ userId, observer }: Props) => {
  return startCollectionDocListener('users', userId, observer);
};
