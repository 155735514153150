import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  endAt,
  endBefore,
  FirestoreError,
  getDoc,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  SetOptions,
  startAfter,
  startAt,
  Timestamp,
  UpdateData,
  updateDoc,
  where,
} from 'firebase/firestore';

import { Constraint, FirebaseConstraintField, FormattedDoc } from '../types/firebase';
import { auth, firestore } from './index';

/**
 * Get a document reference from Firestore.
 * @param path Path to the document.
 * @returns A DocumentReference.
 */
export function getNewDocumentReference(path: string) {
  return doc(firestore, path);
}

/**
 * Start Firestore collection listener.
 * @param collectionPath Collection path.
 * @param docId Document id.
 * @param observer Observer object containing functions.
 * @param queryConstraints Query constraints.
 * @returns Unsubscribe function.
 */
export function startCollectionDocListener<T>(
  collectionPath: string,
  docId: string,
  observer: {
    onNextFn: (snapshot: T) => void;
    onErrorFn?: (error: FirestoreError) => void;
    onCompleteFn?: () => void;
  }
) {
  return onSnapshot(
    doc(firestore, collectionPath, docId),
    (doc) => {
      if (!doc.exists()) {
        const none = {} as T;
        observer.onNextFn(none);
      } else {
        const result = {
          id: doc.id,
          ...doc.data(),
        } as unknown as T;
        observer.onNextFn(result);
      }
    },
    observer.onErrorFn,
    observer.onCompleteFn
  );
}

/**
 * Start Firestore collection listener.
 * @param collectionPath Collection path.
 * @param observer Observer object containing functions.
 * @param queryConstraints Query constraints.
 * @returns Unsubscribe function.
 */
export function startCollectionListener<T>(
  collectionPath: string,
  observer: {
    onNextFn: (snapshot: T[]) => void;
    onErrorFn?: (error: FirestoreError) => void;
    onCompleteFn?: () => void;
  },
  constraints?: Constraint<T>[]
) {
  const q = query<T>(collection(firestore, collectionPath) as CollectionReference<T>, ...buildConstraints(constraints));

  return onSnapshot<T>(
    q,
    (snapshot: QuerySnapshot<T>) => {
      if (snapshot.empty) {
        observer.onNextFn([]);
      } else {
        observer.onNextFn(
          snapshot.docs.map((doc) => ({
            ...formatDocumentDataForRead<T>({
              id: doc.id,
              ...doc.data(),
            }),
          }))
        );
      }
    },
    observer.onErrorFn,
    observer.onCompleteFn
  );
}

/**
 * Get Firestore document reference.
 * @param collectionName Collection name.
 * @param documentId Document id.
 * @returns A Promise of Firestore document reference.
 */
export function getDocumentRef<T>(collectionName: 'artists', documentId: string) {
  let docRef: DocumentReference<T>;

  if (documentId?.length) {
    docRef = doc(firestore, collectionName, documentId) as DocumentReference<T>;
  } else {
    docRef = doc(firestore, collectionName) as DocumentReference<T>;
  }

  return docRef;
}

/**
 * Get Firestore document.
 * @param path Document path.
 * @param pathSegments Document path segments.
 * @returns A Promise of Firestore document reference.
 */
export async function getDocument<T>(path: string, ...pathSegments: string[]) {
  const docRef = doc(firestore, path, ...pathSegments) as DocumentReference<T>;
  const docSnap = await getDoc<T>(docRef);
  if (!docSnap.exists) throw new Error('Document does not exist.');
  return formatDocumentDataForRead<T>({
    id: docSnap.id,
    ...docSnap.data(),
  } as FormattedDoc<T>);
}

/**
 * Add Firestore document.
 * @param path Document path.
 * @param data Data to be added.
 * @returns A Promise of Firestore document reference.
 */
export function addDocument<T>(path: string, data: FormattedDoc<T>) {
  const colRef = collection(firestore, path) as CollectionReference<T>;
  return addDoc<T>(colRef, formatDocumentDataForWrite<T>(data));
}

/**
 * Update Firestore document.
 * @param path Document path.
 * @param data Data fields to be updated.
 * @returns A Promise that resolves after the document has been updated.
 */
export function updateDocument<T>(path: string, data: FormattedDoc<T>) {
  const docRef = doc(firestore, path) as DocumentReference<T>;
  return updateDoc<T>(docRef, formatDocumentDataForWrite<T>(data) as UpdateData<T>);
}

/**
 * Set Firestore document.
 * @param path Document path.
 * @param data Data to be set.
 * @param options Set options.
 * @returns A Promise that resolves when the document has been set.
 */
export function setDocument<T>(path: string, data: T, options: SetOptions) {
  const docRef = doc(firestore, path) as DocumentReference<T>;
  return setDoc<T>(docRef, formatDocumentDataForWrite<T>(data), options);
}

type RawDocData<T> = FormattedDoc<T> | T;
/**
 * Helper function to add audit fields to data.
 * @param data Data to be set.
 * @returns A Document data with audit fields.
 */
function formatDocumentDataForWrite<T>(data: RawDocData<T>): FormattedDoc<T> {
  if (!auth.currentUser?.uid) throw Error('Invalid user');

  // Initialize server timestamp.
  const serverTimeStamp = serverTimestamp() as Timestamp;

  // Add createdBy/updatedBy Object
  const actionBy = {
    uid: auth.currentUser?.uid,
    displayName: auth.currentUser?.displayName ?? auth.currentUser?.email,
    email: auth.currentUser?.email,
  };

  // WARNING! ORDER IS SUPER IMPORTANT HERE
  const formattedData = {
    // IF THESE FIELDS EXIST ON DATA, USE THOSE
    createdAt: serverTimeStamp,
    createdBy: actionBy,
    ...data,
    // UPDATE THESE FIELDS
    updatedBy: actionBy,
    updatedAt: serverTimeStamp,
  } as FormattedDoc<T>;

  return formattedData;
}

/**
 * Helper function to format document data before rendering.
 * @param data Data to be returned.
 * @returns A Document with formatted data.
 */
//
function formatDocumentDataForRead<T>(data: FormattedDoc<T>) {
  const formattedData = data;

  // Changes date Firestore timestamp to JS Date.
  if (data?.createdAt && data.createdAt instanceof Timestamp) {
    formattedData.createdAt = (data.createdAt as Timestamp).toDate().toISOString();
  }

  // Changes date Firestore timestamp to JS Date.
  if (data?.updatedAt && data.updatedAt instanceof Timestamp) {
    formattedData.updatedAt = (data.updatedAt as Timestamp).toDate().toISOString();
  }

  return formattedData;
}

/**
 * Helper function to build Firestore constraints.
 * 'where' | 'orderBy' | 'limit' | 'limitToLast' | 'startAt' | 'startAfter' | 'endAt' | 'endBefore';
 * @param constraints Array of constraints.
 * @returns Array of Firestore query constraints.
 */
function buildConstraints<T>(constraints?: Constraint<T>[]) {
  const queryConstraints: QueryConstraint[] = [];

  if (!constraints) return queryConstraints;

  constraints?.forEach((constraint) => {
    // where
    if (constraint.where) {
      queryConstraints.push(
        where(constraint.where.field as FirebaseConstraintField, constraint.where.operator, constraint.where.value)
      );
    }
    // orderBy
    if (constraint.orderBy) {
      queryConstraints.push(orderBy(constraint.orderBy.field as FirebaseConstraintField, constraint.orderBy.direction));
    }
    // limit
    if (constraint.limit) {
      queryConstraints.push(limit(constraint.limit));
    }
    // limitToLast
    if (constraint.limitToLast) {
      queryConstraints.push(limitToLast(constraint.limitToLast));
    }
    // startAt
    if (constraint.startAt) {
      queryConstraints.push(startAt(constraint.startAt));
    }
    // startAfter
    if (constraint.startAfter) {
      queryConstraints.push(startAfter(constraint.startAfter));
    }
    // endAt
    if (constraint.endAt) {
      queryConstraints.push(endAt(constraint.endAt));
    }

    // endBefore
    if (constraint.endBefore) {
      queryConstraints.push(endBefore(constraint.endBefore));
    }
  });

  return queryConstraints;
}
