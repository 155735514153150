enum INSTANCE_ENVS {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}

const ROLLBAR_CLIENT_TOKEN = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN;
const ROLLBAR_ENV = process.env.NEXT_PUBLIC_ROLLBAR_PROJECT_ENV;
const INSTANCE_ENV = process.env.NEXT_PUBLIC_INSTANCE_ENV || (INSTANCE_ENVS.PROD as INSTANCE_ENVS);

export const checkIsLocal = () => {
  return INSTANCE_ENV === INSTANCE_ENVS.LOCAL;
};

export const checkIsDev = () => {
  return INSTANCE_ENV === INSTANCE_ENVS.DEV;
};

export const checkIsStaging = () => {
  return INSTANCE_ENV === INSTANCE_ENVS.STAGING;
};

export const rollbarConfig = {
  accessToken: ROLLBAR_CLIENT_TOKEN,
  environment: ROLLBAR_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  // whether to send to rollbar
  transmit: !checkIsLocal(),
  // whether to console.log as well
  verbose: checkIsLocal() || checkIsDev() || checkIsStaging(),
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};
