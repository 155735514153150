'use client';

import { useRollbar } from '@rollbar/react';
import { useCallback, useEffect, useState } from 'react';
import Rollbar from 'rollbar';

import { checkIsLocal } from './config';

interface Logger {
  log: (...args: Rollbar.LogArgument[]) => void;
  info: (...args: Rollbar.LogArgument[]) => void;
  warn: (...args: Rollbar.LogArgument[]) => void;
  error: (...args: Rollbar.LogArgument[]) => void;
}

const DefaultLogger: Logger = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
};
const shouldLogLocal = checkIsLocal();

enum METHODS_ENUM {
  log = 'log',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

interface BaseLogParams {
  method: METHODS_ENUM;
  args: Rollbar.LogArgument[];
}

export default function useLogger() {
  const [logger, setLogger] = useState(DefaultLogger);
  const rollbar = useRollbar();

  const baseLogAction = useCallback(
    ({ method, args }: BaseLogParams) => {
      if (shouldLogLocal) {
        console[method](...args);
      }
      return rollbar[method](...args);
    },
    [rollbar]
  );

  useEffect(() => {
    if (rollbar) {
      setLogger({
        log: function (...args: Rollbar.LogArgument[]) {
          return baseLogAction({ method: METHODS_ENUM.log, args: args });
        },
        info: function (...args: Rollbar.LogArgument[]) {
          return baseLogAction({ method: METHODS_ENUM.info, args: args });
        },
        warn: function (...args: Rollbar.LogArgument[]) {
          return baseLogAction({ method: METHODS_ENUM.warn, args: args });
        },
        error: function (...args: Rollbar.LogArgument[]) {
          return baseLogAction({ method: METHODS_ENUM.error, args: args });
        },
      });
    }
  }, [rollbar, baseLogAction]);

  return {
    log: logger.log,
    info: logger.info,
    warn: logger.warn,
    error: logger.error,
  };
}
