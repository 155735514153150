'use client';

import { useContext } from 'react';

import { AuthContext } from '../providers/AuthProvider';

// Custom hook that shorthands the auth context!
const useAuth = () => useContext(AuthContext);

export default useAuth;
